import React from 'react'
import PropTypes from 'prop-types'
import {graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import { Link } from 'gatsby'
const item1 = {image: "/img/group_yujie.png"}
const item2 = {image: "/img/group_paul.png"}
const item3 = {image: "/img/group_rayne.png"}
const item4 = {image: "/img/group_frank.png"}
const item5 = {image: "/img/group_sue.png"}
const item6 = {image: "/img/group_sky.jpg"}

export const GroupPageTemplate = ({ title, content, contentComponent,}) => {
  const PageContent = contentComponent || Content

  return (
    <section className="section section--gradient" style ={{padding:'0rem 1.5rem'}}>
      <div className="container">
      <div
      className="full-width-image-container margin-top-0"
      style={{
            backgroundImage: `url('/img/group.png')`,
      }}
      >
      <h2
        className="has-text-weight-bold is-size-1"
        style={{
          boxShadow: '0.5rem 0 0 #f40, -0.5rem 0 0 #f40',
          backgroundColor: '#f40',
          color: 'white',
          padding: '1rem',
        }}
      >
        {title}
      </h2>
      </div>
        <div className="columns">

          <div className="column is-10 is-offset-1">
            <div className="section" style = {{padding:'1rem 1.5rem'}}>

            
            <PageContent className="content" content={content} />
            <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
              Lab Lead
            </h2>
            <div className="column box" style={{padding:'1rem'}}>
              
              <div className="tile is-ancestor">
                <div className="tile is-vertical">
                  <div className="tile">
                    <div className="tile is-parent is-vertical">
                      <article className="tile is-child" >
                      <div
                      style={{
                        width: '360px',
                      }}
                      >
                        <PreviewCompatibleImage imageInfo={item6}/>
                      </div>
                        <p className="subtitle">
                        Sikai Chen
                        <p>
                        <Link className="group-child" to="https://scholar.google.com/citations?user=DPN2wc4AAAAJ&hl=en" >
                        [Google scholar]
                        </Link>
                        <Link className="group-child" to="https://www.linkedin.com/in/sikai-chen-14920860/" style={{padding:'0.5rem'}}>
                        [LinkedIn]
                        </Link>
                        <Link className="group-child" to="https://scholar.google.com/citations?user=DPN2wc4AAAAJ&hl=en" >
                        [CV]
                        </Link>
                        </p>
                        </p>
                      </article>
                    </div>
                    <div className="tile is-parent column is-10">
                      <article className="tile is-child" style={{color:'#000000'}}>
                      <p >
                      Current Position:
                      </p>
                      <p style={{padding:'0.5rem'}}>
                      • Visiting Assistant Professor, Center for Connected and Automated Transportation <br></br>(CCAT), Purdue University<br></br>
                      • Visiting Assistant Professor, Lyles School of Civil Engineering, Purdue University<br></br>
                      • Visiting Research Fellow, Robotics Institute, School of Computer Science, <br></br>Carnegie Mellon University
                      </p>
                      <p>
                      Education:
                      </p>
                      <p style={{padding:'0.5rem'}}>
                       • Ph.D, Civil Engineering / Computational Science and Engineering, Purdue University, 2019<br></br>
                       • M.S, Transportation Engineering, University of Southern California, 2014
                      </p>
                      <p>
                      Research interests:
                      </p>
                      <p style={{padding:'0.5rem'}}>
                       • Applications: Connected and autonomous vehicle; <br></br>Human-computer interaction; Highway safety analysis; Transportation infrastructure <br></br>
                       systems management; Smart cities; Intelligent transportation systems; Urban computing; <br></br>
                       Multi-agent systems; Vehicle automation, simulation, and control.<br></br>
                       • Operations Research Tools: Artificial Intelligence; Machine learning; Deep learning;<br></br> Reinforcement learning; Computer vision; Optimization; Simulation; Mathematical<br></br>
                        programming; Econometrics and statistics; Numerical analysis; Big data analytics. 
                      </p>
                      <p>
                      Email: chen1670@purdue.edu; sikaichen@cmu.edu
                      </p>
                      
                      
                      
                      </article>
                    </div>
                  </div>
                  
                </div>
              </div>
              
              </div>

              <PageContent className="content" content={content} />
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
              Group Members
              </h2>
              <div className="column box" style={{padding:'1rem'}}>
              <div className="tile is-ancestor">
                <div className="tile is-vertical">
                  <div className="tile">
                    <div className="tile is-parent is-vertical">
                      <article className="tile is-child" >
                        <div
                      style={{
                        width: '360px',
                      }}
                      >
                        <PreviewCompatibleImage imageInfo={item4}/>   
                      </div>
                        <p className="subtitle">
                        Jiqian Dong<br></br>
                        <p >
                      <Link className="group-child " to="https://scholar.google.com/citations?user=UJ1SNjYAAAAJ&hl=en" >
                        [Google scholar]
                        </Link>
                        <Link className="group-child" to="https://github.com/JiqianDong" style={{padding:'0.5rem'}}>
                        [Github]
                        </Link>
                        <Link className="group-child" to="https://www.linkedin.com/search/results/people/?firstName=Jiqian&lastName=Dong&origin=SEO_PSERP" >
                        [LinkedIn]
                        </Link>
                        <Link className="group-child" to="https://www.linkedin.com/search/results/people/?firstName=Jiqian&lastName=Dong&origin=SEO_PSERP" >
                        [CV]
                        </Link>
                        <Link className="group-child" to="https://www.linkedin.com/search/results/people/?firstName=Jiqian&lastName=Dong&origin=SEO_PSERP" style={{padding:'0.5rem'}} >
                        [Personal Website]
                        </Link>
                      </p>
                        
                        </p>
                      </article>
                    </div>
                    <div className="tile is-child column is-10">
                      <article className="tile is-child" style={{color:'#000000'}}>
                      <p>
                      Education:
                      </p>
                      <p style={{padding:'0.5rem'}}>
                       •  PhD Student,  Purdue University, Since 2019<br></br>
                       •  M.S Civil Engineering, Carnegie Mellon University, 2019 <br></br>
                       • B.Eng.  Bridge Engineering, Tongji University,  2017
                      </p>
                      <p>
                      Research interests:
                      </p>
                      <p style={{padding:'0.5rem'}}>
                       •  AI-based control for connected autonomous vehicles<br></br>
                       •  Machine learning for autonomous driving operation <br></br>
                       •  Connected infrastructure system
                      </p>
                      <p>
                      Email:
                      </p>
                      <p style={{padding:'0.5rem'}}>
                      •  dong282@purdue.edu
                      </p>
                      
                      
                      
                      </article>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <br></br>
          <div className="column box" style={{padding:'1rem'}}>
              
              <div className="tile is-ancestor">
                <div className="tile is-vertical">
                  <div className="tile">
                    <div className="tile is-parent is-vertical">
                      <article className="tile is-child" >
                      <div
                      style={{
                        width: '360px',
                      }}
                      >
                        <PreviewCompatibleImage imageInfo={item3}/>
                       </div> 
                        <p className="subtitle">
                        Runjia Du
                        <p >
                        <Link className="group-child" to="https://scholar.google.com/citations?user=AJFZfkIAAAAJ&hl=en" >
                        [Google scholar]
                        </Link>
                        <Link className="group-child" to="https://github.com/Rayne-1020" style={{padding:'0.5rem'}}>
                        [Github]
                        </Link>
                        <Link className="group-child" to="https://www.linkedin.com/in/runjia-du-1bb049201/">
                        [LinkedIn]
                        </Link>
                        <Link className="group-child" to="https://www.linkedin.com/in/runjia-du-1bb049201/"> 
                        [CV]
                        </Link>
                        <Link className="group-child" to="https://scholar.google.com/citations?user=AJFZfkIAAAAJ&hl=en" style={{padding:'0.5rem'}}>
                        [Personal Website]
                        </Link>
                        </p>
                        </p>
                      </article>
                    </div>
                    <div className="tile is-parent column is-10">
                      <article className="tile is-child" style={{color:'#000000'}}>

                        
                      <p>
                      Education:
                      </p>
                      <p style={{padding:'0.5rem'}}>
                       •  PhD Student,  Purdue University, Since 2019<br></br>
                       •  M.S Civil Engineering, Purdue University, 2019 <br></br>
                       •  B.Eng.  Transportation Engineering, ECJTU,  2017
                      </p>
                      <p>
                      Research interests:
                      </p>
                      <p style={{padding:'0.5rem'}}>
                       •  Optimal and AI-based control for connected autonomous vehicles<br></br>
                       •  Machine learning for autonomous driving operation <br></br>
                       •  Traffic safety in Mixed Traffic Flow 
                      </p>
                      <p>
                      Email:
                      </p>
                      <p style={{padding:'0.5rem'}}>
                       •  du187@purdue.edu
                      </p>
                      
                      </article>
                    </div>
                  </div>
                  
                </div>
              </div>
              
              </div>
              <br></br>
          <div className="column box" style={{padding:'1rem'}}>
              <div className="tile is-ancestor">
                <div className="tile is-vertical">
                  <div className="tile">
                    <div className="tile is-parent is-vertical">
                      <article className="tile is-child" >
                      <div
                      style={{
                        width: '360px',
                      }}
                      >
                        <PreviewCompatibleImage imageInfo={item2}/>
                       </div>
                        <p className="subtitle">
                        Paul(Young Joun) Ha
                        <p >
                        <Link className="group-child" to="https://scholar.google.com/citations?user=VagNU_AAAAAJ&hl=en" >
                        [Google scholar]
                        </Link>
                        <Link className="group-child" to="https://github.com/habibi1195" style={{padding:'0.5rem'}}>
                        [Github]
                        </Link>
                        <Link className="group-child" to="https://www.linkedin.com/in/paulha1195/">
                        [LinkedIn]
                        </Link>
                        <Link className="group-child" to="https://www.linkedin.com/in/paulha1195/" >
                        [CV]
                        </Link>
                        <Link className="group-child" to="https://www.linkedin.com/in/paulha1195/" style={{padding:'0.5rem'}}>
                        [Personal Website]
                        </Link>
                        </p>
                        
                        </p>
                      </article>
                    </div>
                    <div className="tile is-parent column is-10">
                      <article className="tile is-child" style={{color:'#000000'}}>

                        
                      <p>
                      Education:
                      </p>
                      <p style={{padding:'0.5rem'}}>
                       •  B.Eng.  Civil Engineering, NYU Abu Dhabi,  2017<br></br>
                       •  M.S Civil Engineering, Purdue University, 2019 <br></br>
                       •  PhD Student,  Purdue University, Since 2019
                      </p>
                      <p>
                      Research interests:
                      </p>
                      <p style={{padding:'0.5rem'}}>
                       •  AI-based control for connected autonomous vehicles<br></br>
                       •  Machine learning for autonomous driving operation <br></br>
                       •  Connected infrastructure system
                      </p>
                      <p>
                      Email:
                      </p>
                      <p style={{padding:'0.5rem'}}>
                       •  ha55@purdue.edu
                      </p>
                      
                      </article>
                    </div>
                  </div>
                  
                </div>
              </div>
              
              </div>
              <br></br>
          <div className="column box" style={{padding:'1rem'}}>
              
              <div className="tile is-ancestor">
                <div className="tile is-vertical">
                  <div className="tile">
                    <div className="tile is-parent is-vertical">
                      <article className="tile is-child" >
                      <div
                      style={{
                        width: '360px',
                      }}
                      >
                        <PreviewCompatibleImage imageInfo={item1}/>
                      </div>  
                        <p className="subtitle">
                        Yujie Li
                        <p>
                        <Link className="group-child" to="https://scholar.google.com/citations?user=AJFZfkIAAAAJ&hl=en">
                        [Google scholar]
                        </Link>
                        <Link className="group-child" to="https://github.com/Rayne-1020" style={{padding:'0.5rem'}}>
                        [Github]
                        </Link>
                        <Link className="group-child" to="https://www.linkedin.com/in/yujie-li-673251191/">
                        [LinkedIn]
                        </Link>
                        <Link className="group-child" to="https://scholar.google.com/citations?user=AJFZfkIAAAAJ&hl=en" >
                        [CV]
                        </Link>
                        <Link className="group-child" to="https://www.linkedin.com/in/yujie-li-673251191/" style={{padding:'0.5rem'}}>
                        [Personal Website]
                        </Link>
                        </p>
                        
                        </p>
                      </article>
                    </div>
                    <div className="tile is-parent column is-10">
                      <article className="tile is-child" style={{color:'#000000'}}>

                      <p>
                      Education:
                      </p>
                      <p style={{padding:'0.5rem'}}>
                       •  B.Eng.  Transportation Engineering, SouthEast University,  2017<br></br>
                       •  M.S Civil Engineering, Purdue University, 2019 <br></br>
                       •  PhD Student,  Purdue University, Since 2019
                      </p>
                      <p>
                      Research interests:
                      </p>
                      <p style={{padding:'0.5rem'}}>
                       •  Optimal and AI-based control for connected autonomous vehicles<br></br>
                       •  Machine learning for autonomous driving operation <br></br>
                       •  Human factors in emerging AV technologies
                      </p>
                      <p>
                      Email:
                      </p>
                      <p style={{padding:'0.5rem'}}>
                       •  li2804@purdue.edu
                      </p>
                      
                      </article>
                    </div>
                  </div>
                  
                </div>
              </div>
              
              </div>
              <br></br>
          <div className="column box" style={{padding:'1rem'}}>
              
              <div className="tile is-ancestor">
                <div className="tile is-vertical">
                  <div className="tile">
                    <div className="tile is-parent is-vertical">
                      <article className="tile is-child" >
                      <div
                      style={{
                        width: '360px',
                      }}
                      >
                        <PreviewCompatibleImage imageInfo={item5}/>
                      </div>
                        <p className="subtitle">
                        Shuya Zong
                        <p>
                        <Link className="group-child" to="https://scholar.google.com/citations?user=AJFZfkIAAAAJ&hl=en">
                        [Google scholar]
                        </Link>
                        <Link className="group-child" to="https://github.com/Rayne-1020" style={{padding:'0.5rem'}}>
                        [Github]
                        </Link>
                        <Link className="group-child" to="https://www.linkedin.com/in/shuya-zong-1a689b16a/?originalSubdomain=hk">
                        [LinkedIn]
                        </Link>
                        <Link className="group-child" to="https://github.com/Rayne-1020" >
                        [CV]
                        </Link>
                        <Link className="group-child" to="https://www.linkedin.com/in/shuya-zong-1a689b16a/?originalSubdomain=hk" style={{padding:'0.5rem'}}>
                        [Personal Website]
                        </Link>
                        </p>
                        
                        </p>
                      </article>
                    </div>
                    <div className="tile is-parent column is-10">
                      <article className="tile is-child" style={{color:'#000000'}}>

                        
                      <p>
                      Education:
                      </p>
                      <p style={{padding:'0.5rem'}}>
                       •  B.Eng.  Civil Engineering, Hong Kong Polytechnic University,  2020<br></br>
                       •  PhD Student,  Purdue University, Since 2020
                      </p>
                      <p>
                      Research interests:
                      </p>
                      <p style={{padding:'0.5rem'}}>
                       •  Optimal and AI-based control for connected autonomous vehicles<br></br>
                       •  Machine learning for autonomous driving operation <br></br>
                       •  Human factors in emerging AV technologies
                      </p>
                      <p>
                      Email:
                      </p>
                      <p style={{padding:'0.5rem'}}>
                       •  szong@purdue.edu
                      </p>
                      
                      </article>
                    </div>
                  </div>
                  
                </div>
              </div>
              
              </div>


            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

GroupPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,

  
}

const GroupPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <GroupPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        
      />
    </Layout>
  )
}

GroupPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default GroupPage

export const groupPageQuery = graphql`
  query GroupPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
      
    }
  }
`
